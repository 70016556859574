<template>
  <header>
    <div class="header-inner">
      <div class="top-bar">
        <div class="d-flex justify-content-start align-items-center">
          <div class="text-white col-5 subtitle">
            <NuxtLink to="/"> &lt; לעמוד הבית</NuxtLink>
          </div>
          <div class="logo col-md-2 text-md-center text-center">
            <NuxtLink to="/" class="mx-auto block">
              <nuxt-img
                  class="mx-auto max-w-[50px]"
                  loading="lazy"
                  :src="options?.logo?.node?.sourceUrl"
                  alt="לוגו חברת שטיחי SASA"
              />
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
const {options} = useGlobalStore();
</script>
